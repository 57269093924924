<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="30%">
      <v-card class="pa-10 not-margin">
        <v-form ref="form">
          <v-row>
            <v-col class="text-center">
              <img src="/img/brasao_64.png" alt="Brasão de Pernambuco">
              <h1
                class="subtitle-1 blue--text text--darken-4"
                v-text="content.primeiroAcesso ? 'Altere a senha para acessar as funcionalidades do AVP' : 'Alterar senha AVP'"
              ></h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                solo
                placeholder="Senha Atual"
                hide-details="auto"
                v-model="form.senhaAtual"
                v-if="!content.primeiroAcesso"
                @click:append="mostrarSenhaAtual = !mostrarSenhaAtual"
                :type="mostrarSenhaAtual ? 'text' : 'password'"
                :append-icon="mostrarSenhaAtual ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="[
                  (v) => $required(v),
                  (v) => $max(v, 8),
                  (v) => $min(v, 6)
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                solo
                placeholder="Nova Senha"
                hide-details="auto"
                v-model="form.novaSenha"
                @click:append="mostrarNovaSenha = !mostrarNovaSenha"
                :type="mostrarNovaSenha ? 'text' : 'password'"
                :append-icon="mostrarNovaSenha ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="[
                  (v) => $required(v, 'Senha é obrigatória'),
                  (v) => $max(v, 8),
                  (v) => $min(v, 6),
                  (v) => !(v == form.senhaAtual) || 'A nova senha não pode ser igual a anterior!'
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                solo
                placeholder="Confirmar Senha"
                hide-details="auto"
                v-model="form.confirmarSenha"
                v-if="!content.primeiroAcesso"
                @click:append="mostrarConfirmarSenha = !mostrarConfirmarSenha"
                :type="mostrarConfirmarSenha ? 'text' : 'password'"
                :append-icon="mostrarConfirmarSenha ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="[
                  (v) => $required(v),
                  (v) => $max(v, 8),
                  (v) => $min(v, 6),
                  (v) => v == form.novaSenha || 'A nova senha e a confirmação de senha devem ser iguais!'
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-10">
            <v-col :cols="content.primeiroAcesso ? 12 : 6">
              <v-btn block color="success" @click="salvar()">
                Mudar Senha
              </v-btn>
            </v-col>
            <v-col v-if="!content.primeiroAcesso" cols="6">
              <v-btn block color="success" @click="$emit('onClose')">
                Fechar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, TrocarSenha } from '~/commons/classes';

export default {
  props: new DialogProps(TrocarSenha),
  data: () => ({
    form: {
      senhaAtual: null,
      novaSenha: null,
      confirmarSenha: null
    },
    mostrarSenhaAtual: false,
    mostrarNovaSenha: false,
    mostrarConfirmarSenha: false,
  }),
  watch: {
    visible(v) {
      !v || this.$resetForm();
    }
  },
  methods: {
    salvar() {
      if (this.$validarFormulario()) {
        const objEnvio = {
          nomeDeUsuario: this.content.nomeDeUsuario,
          senha: this.form.novaSenha,
          senhaAtual: this.form.senhaAtual,
          token: this.content.token
        }
        this.$emit("onClose");
        this.$mudarSenha(objEnvio).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
        })
      }
    }
  },
};
</script>
